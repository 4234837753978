import {
  getFieldOptions,
  IField,
  IFieldWithData
} from '@element451-libs/forms451';
import {
  ApplicationsApi,
  AuthApi,
  ElmDatePipeFormat,
  FormsApi
} from '@element451-libs/models451';
import { get } from '@element451-libs/utils451/get';
import { isPrimitive } from '@element451-libs/utils451/helpers';
import { formatDate } from '@element451-libs/utils451/pipes';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { produce } from 'immer';
import { isArray, isNil, isPlainObject, keys, uniqBy, xorWith } from 'lodash';
import { ACCOUNT_ACTIONS, AccountAction } from '../account/account.actions';
import { selectApp } from '../app.feature';
import { RECOMMEND_ACTIONS, RecommendAction } from './recommend.actions';

export interface RecommendState {
  loading: boolean;
  loaded: boolean;
  submitting: boolean;
  student: AuthApi.UserProperties;
  loginInfo: Partial<ApplicationsApi.LoginInfo>;
  recommendation: Partial<ApplicationsApi.Recommendation>;
  documents: ApplicationsApi.RecommedationDocuments;
  form: FormsApi.Field[];
  infoRequest: ApplicationsApi.InfoRequest;
}
const initialState = {
  loading: false,
  loaded: false,
  submitting: false,
  student: null,
  loginInfo: null,
  recommendation: null,
  documents: null,
  form: null,
  infoRequest: null
};

export function recommendReducer(
  state: RecommendState = initialState,
  action: RecommendAction | AccountAction
): RecommendState {
  switch (action.type) {
    case RECOMMEND_ACTIONS.ADD_REQUEST:
      return { ...state, submitting: true };

    case RECOMMEND_ACTIONS.ADD_SUCCESS: {
      const documents = get(action.payload, 'documents');

      return {
        ...state,
        submitting: false,
        documents,
        recommendation: {
          ...state.recommendation,
          status: ApplicationsApi.InfoRequestStatus.Submitted
        }
      };
    }

    case RECOMMEND_ACTIONS.ADD_FILE_SUCCESS: {
      return produce(state, draft => {
        if (!draft.documents) {
          draft.documents = {
            data: {},
            files: []
          };
        }
        if (!draft.documents.files) {
          draft.documents.files = [];
        }

        let newFiles = xorWith(
          state.documents.files,
          action.payload.currentAllDocuments.files,
          (f1, f2) => f1.guid === f2.guid
        );

        // attach missing properties to new files
        newFiles = newFiles.map(file => {
          if (file.field_name) {
            return file;
          }
          file.field_name = action.payload.fieldName;

          if (file.url.includes('/private')) {
            const apiOrigin = new URL(action.payload.apiUrl).origin;
            file.url = `${apiOrigin}/v2/files/download/${file.guid}/${file.access_token}`;
          }

          return file;
        });

        draft.documents.files.push(...newFiles);
        draft.documents.files = uniqBy(
          draft.documents.files,
          file => file.guid
        );
      });
    }

    case RECOMMEND_ACTIONS.REMOVE_FILE_SUCCESS: {
      return produce(state, draft => {
        draft.documents.files = state.documents.files.filter(
          file => file.guid !== action.payload.fileGuid
        );
      });
    }

    case RECOMMEND_ACTIONS.ADD_FAIL:
      return { ...state, submitting: false };

    case ACCOUNT_ACTIONS.LOCKER_URL_LOGIN_REQUEST:
    case ACCOUNT_ACTIONS.LOCKER_SESSION_LOGIN_REQUEST:
      return { ...state, loading: true };

    case ACCOUNT_ACTIONS.SIGN_IN_SUCCESS:
    case ACCOUNT_ACTIONS.LOCKER_URL_LOGIN_SUCCESS:
    case ACCOUNT_ACTIONS.LOCKER_SESSION_LOGIN_SUCCESS: {
      const isStudent =
        get(action.payload, 'data', 'loginInfo', 'usageScope') ===
        'student_login';

      if (isStudent) return state;

      return {
        ...state,
        loading: false,
        loaded: true,
        ...normalizeState(action.payload.data)
      };
    }

    case ACCOUNT_ACTIONS.LOCKER_SESSION_LOGIN_FAIL:
    case ACCOUNT_ACTIONS.LOCKER_URL_LOGIN_FAIL:
      return { ...state, loading: false, loaded: false };

    default:
      return state;
  }
}

export const recommendFeature = 'recommend';

const _selectRecommendState =
  createFeatureSelector<RecommendState>(recommendFeature);

export const selectRecommendState = createSelector(
  selectApp,
  _selectRecommendState
);

export const selectLoading = createSelector(
  selectRecommendState,
  state => state.loading
);

export const selectLoaded = createSelector(
  selectRecommendState,
  state => state.loaded
);

export const selectSubmitting = createSelector(
  selectRecommendState,
  state => state.submitting
);

export const selectStudent = createSelector(
  selectRecommendState,
  state => state.student
);

export const selectLoginInfo = createSelector(
  selectRecommendState,
  state => state.loginInfo
);

export const selectRecommendation = createSelector(
  selectRecommendState,
  state => state.recommendation
);

export const selectSubmitted = createSelector(
  selectRecommendation,
  recommendation =>
    get(recommendation, 'status') ===
    ApplicationsApi.InfoRequestStatus.Submitted
);

export const selectDocuments = createSelector(
  selectRecommendState,
  state => state.documents
);

export const selectFormFields = createSelector(
  selectRecommendState,
  state => state.form
);

export const selectForm = createSelector(selectFormFields, fields => ({
  fields
}));

const selectDocumentsData = createSelector(
  selectDocuments,
  documents => get(documents, 'data') || {}
);

export const selectSummary = createSelector(
  selectDocumentsData,
  selectFormFields,
  getSummary
);

function getSummary(
  data: { [key: string]: string },
  fields: IField[]
): {
  label: string;
  value?: string | number;
  subfields?: { label: string; value: string | number }[];
}[] {
  const fieldMap = fields.reduce(
    /** keyed by label */
    (map, field) => map.set(field.label, field),
    new Map<string, FormsApi.Field>()
  );

  return keys(data).reduce((summaryList, label) => {
    let value = data[label];
    const field = fieldMap.get(label);

    if (field) {
      switch (field.type) {
        case 'select':
        case 'radio': {
          const options = getFieldOptions(field as any as IField).reduce(
            (optionMap, option) => optionMap.set(option.value, option.text),
            new Map<string, string>()
          );

          value = options.get(value) || value;

          summaryList.push({ label, value });
          break;
        }
        case 'checkbox': {
          const options = getFieldOptions(field as any as IField).reduce(
            (optionMap, option) => optionMap.set(option.value, option.text),
            new Map<string, string>()
          );

          const selected = isPlainObject(value)
            ? Object.entries(value)
                .filter(([_, val]) => !!val)
                .map(([key, _]) => key as string)
            : isArray(value)
              ? (value as string[])
              : [value];

          value = selected.map(key => options.get(key)).join(', ');
          summaryList.push({
            label,
            value
          });
          break;
        }
        case 'date':
        case 'datepicker': {
          summaryList.push({
            label,
            value: formatDate(value, ElmDatePipeFormat.MediumDate)
          });
          break;
        }
        case 'file':
          break;
        default: {
          if (field.subfields?.length && isPlainObject(value)) {
            summaryList.push({
              label,
              subfields: extractSubfieldsSummary(field, value as unknown)
            });
          } else {
            summaryList.push({
              label,
              value: isPrimitive(value) ? value : 'N/A'
            });
          }
          break;
        }
      }
    }
    return summaryList;
  }, []);
}

function extractSubfieldsSummary(field: IField, value: Record<string, any>) {
  const subfieldsValues = {};
  const subfields = [];

  for (const subfield of field.subfields) {
    if (subfield.hidden) continue;

    const key = subfield.name?.replace(`${field.name}-`, '');
    const val = value[key]?.value;

    if (isNil(val)) continue;

    subfields.push(subfield);
    subfieldsValues[subfield.label] = val;
  }

  return getSummary(subfieldsValues, subfields);
}

export const selectInfoRequest = createSelector(
  selectRecommendState,
  state => state.infoRequest
);

export const selectRequestInfo = createSelector(
  selectInfoRequest,
  infoRequest => get(infoRequest, 'request_info')
);

export const selectHeaderTitle = createSelector(
  selectRequestInfo,
  selectSubmitted,
  (requestInfo, submitted) =>
    submitted
      ? get(requestInfo, 'greeting-title-submitted')
      : get(requestInfo, 'greeting-title')
);

export const selectHeaderDescription = createSelector(
  selectRequestInfo,
  selectSubmitted,
  (requestInfo, submitted) =>
    submitted
      ? get(requestInfo, 'greeting-description-submitted')
      : get(requestInfo, 'greeting-description')
);

export const selectPageTitle = createSelector(
  selectRequestInfo,
  selectSubmitted,
  (requestInfo, submitted) =>
    submitted
      ? get(requestInfo, 'form-title-submitted')
      : get(requestInfo, 'form-title')
);

function normalizeState(data: ApplicationsApi.RequestLocker) {
  const student = get(data, 'properties');

  const { recommendation, recommender_form, info_request, ...loginInfo } =
    data.loginInfo;

  const { documents, ...recommendationRest } = recommendation;

  return {
    student,
    loginInfo,
    documents,
    recommendation: recommendationRest,
    form: recommender_form,
    infoRequest: info_request
  };
}

export const selectUploadedFiles = createSelector(
  selectDocuments,
  documents => get(documents, 'files') || ([] as ApplicationsApi.RequestFile[])
);

/**
 * we only prepopulate files, so we need to extract them
 */
export const selectFormData = createSelector(selectUploadedFiles, files => {
  const fileDir = {};
  for (const file of files) {
    if (!fileDir[file.field_name]) {
      fileDir[file.field_name] = [];
    }
    fileDir[file.field_name].push(file);
  }
  return Object.keys(fileDir).map(key => ({
    key,
    value: {
      files: fileDir[key]
    }
  })) as IFieldWithData[];
});
